import { Flex, GridItem, Skeleton, TagLabel, Tooltip, chakra } from '@chakra-ui/react';
import React from 'react';

import capitalizeFirstLetter from 'lib/capitalizeFirstLetter';
import Tag from 'ui/shared/chakra/Tag';
import type { IconName } from 'ui/shared/IconSvg';
import IconSvg from 'ui/shared/IconSvg';
import { getEnvValue } from 'configs/app/utils';
import Hint from '../Hint';
import HintPopover from '../HintPopover';

export type StatusTagType = 'ok' | 'error' | 'pending' | 'confirmed' | 'verified' | 'finalized';

export interface Props {
  type: 'ok' | 'error' | 'pending' | 'confirmed' | 'verified' | 'finalized' ;
  text: string;
  l1height?: number | null;
  errorText?: string | null;
  info?: string | null;
  isLoading?: boolean;
  className?: string;
}

const StatusTag = ({ type, text, l1height ,errorText, isLoading, className, info }: Props) => {
  let icon: IconName;
  let colorScheme;
  let rollup_url = getEnvValue('NEXT_PUBLIC_ROLLUP_L1_BASE_URL') || '';

  const capitalizedText = capitalizeFirstLetter(text);

  switch (type) {
    case 'ok':
      icon = 'status/success';
      colorScheme = 'green';
      break;
    case 'error':
      icon = 'status/error';
      colorScheme = 'red';
      break;
    case 'pending':
      icon = 'status/pending';
      colorScheme = 'gray';
      break;
    case 'confirmed':
      icon = 'status/pending';
      colorScheme = 'gray';
      break;
    case 'verified':
      icon = 'status/success';
      colorScheme = 'green';
      break;
    case 'finalized':
      icon = 'status/success';
      colorScheme = 'green';
      break;
  }

  return (
    <Tooltip label={ errorText } style = {{"fontSize": "20px"}}>
      <Tag colorScheme={ colorScheme } display="flex" isLoading={ isLoading } className={ className }>
{ info !== undefined || info !== null ? 
        <Flex columnGap={ 4 } alignItems="flex-start">
            <HintPopover label={ info } isLoading={ isLoading } my={{ lg: '2px' }}/> 
        </Flex>
:<></>}

        <IconSvg boxSize={ 2.5 } name={ icon } mr={ 1 } flexShrink={ 0 }/>
        <TagLabel display="block">{ capitalizedText } </TagLabel>
        {l1height !== undefined && l1height !== null ?<><span> &nbsp; | &nbsp; </span> <a style={{textDecoration: "underline"}} target="blank" href={rollup_url+"/block/"+l1height}>See on explorer </a></>: <></>}
      </Tag>
    </Tooltip>
  );
};

export default chakra(StatusTag);
